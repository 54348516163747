import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@material-ui/core";
import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import { addMinutes, format as formatFn } from "date-fns";
import { ChangeEvent, FocusEvent, useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { FormValidators } from "..";
import { TIME_DISPLAY_FORMAT } from "../../../utils/dates";
import { deepmerge } from "../../../utils/objects";
import { parseDuration } from "./DurationControl";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

export const parseTime = (value: string, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;

  // help lazy input look like something chrono can parse (eg. "1" => "1:00")
  let newValue = /^\d{0,2}$/.test(value.trim()) ? `${value}:00` : value;
  // 1:00a => 1:00 a
  newValue = newValue.replace(/(?![\d]+)([ap]m?)/i, (m) => ` ${m.toLowerCase()}`);
  // 2030 => 8:30 pm (note: limit length to eliminate conflicts with long dates)
  if (newValue.length <= 4) {
    newValue = newValue.replace(/([\d]{1,2}(?!:)[\d]{2})/i, (m) => `${m.slice(0, -2)}:${m.slice(-2)}`);
  }

  return parseDate(newValue, undefined, options);
};

export const TimeControlValidators: FormValidators = {
  afterTime: (value: string, time: string, error: string) => {
    const timeParsed = parseDate(time);
    const valueParsed = parseTime(value);
    return !valueParsed || !timeParsed || timeParsed <= valueParsed || error;
  },
  beforeTime: (value: string, time: string, error: string) => {
    const timeParsed = parseDate(time);
    const valueParsed = parseTime(value);
    return !valueParsed || !timeParsed || timeParsed >= valueParsed || error;
  },
  lessThanDuration: (start: string, end: string, duration: string, error?: string) => {
    const startParsed = parseDate(start);
    const endParsed = parseTime(end);
    const durationParsed = parseDuration(duration || "15 min");
    return (
      !end ||
      !start ||
      addMinutes(startParsed, durationParsed as number) <= (endParsed as Date) ||
      error || `Not enough time (${duration})`
    );
  }
};

export type TimeControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps & {};

export type TimeControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, TimeControlRenderProps>, "render"> & {
    format?: string;
    chronoOptions?: ChronoParsingOption;
    required?: string | boolean;
  };

export const TimeControl: IRhfControl<TimeControlProps> = ({
  rules,
  format = TIME_DISPLAY_FORMAT,
  chronoOptions,
  required,
  ...rest
}) => {
  const merged: TimeControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
        validate: {
          // unambiguous: (v: string) => !!v && /^\d(\:(\d){0,2})?$/.test(v.trim()) || "am or pm",
          parseable: (v: string) => !v || !!parseTime(v, chronoOptions) || "invalid time",
        },
      }),
    [chronoOptions, rules, required]
  );

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: TimeControlRenderProps) => {
      const { name, value, ref: inputRef, onChange, onBlur } = field;

      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        rest.onChange?.(e);
      };

      const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const parsed = parseTime(e.target.value, chronoOptions);

        // input is a valid time format
        if (!!parsed) {
          onChange(formatFn(parsed, format));
        }

        onBlur();
        rest.onBlur?.(e);
      };

      return (
        <MuiTextField
          {...rest}
          inputRef={inputRef}
          {...{ name, value, onChange: handleChange, onBlur: handleBlur }}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      );
    },
    [chronoOptions, format]
  );

  return <RhfControlled {...rest} rules={merged} render={render} />;
};

TimeControl.isControl = true;
TimeControl.isController = true;
